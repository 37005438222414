import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ThemeButton } from '@src/components/molecules';
import { TextFormV2 } from '@src/components/shared';
// import { CURRENCY_SYMBOLS } from '@src/libs/constant';
// import { formatNumberWithCommas } from '@src/libs/format';
import { useDeepCompareEffect, usePageLayout } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { AllCampaignsSearchJobsOrderBy, CampaignSocialMediaType } from '@src/__generated__/globalTypes';
import MultiCheckBoxSelect from './MultiCheckBoxSelect';

export interface FilterItems {
  categoryIds: string;
  keyword: string;
  maxPrice: string;
  maxReward: string;
  minPrice: string;
  minReward: string;
  orderBy: AllCampaignsSearchJobsOrderBy;
  promotionMethods: string;
}

interface SearchFilterProps {
  campaignCategories: { label: string; value: string }[];
  currency: string;
  filter: FilterItems;
  promotionMethods: { label: string; value: CampaignSocialMediaType }[];
  visible: boolean;
  onCancel: () => void;
  setFilter: (values: FilterItems) => void;
}

const SearchFilter = ({
  campaignCategories,
  // currency,
  filter,
  promotionMethods,
  visible,
  onCancel,
  setFilter,
}: SearchFilterProps) => {
  const [searchInput, setSearchInput] = useState<FilterItems>(filter);
  const { isMobileView } = usePageLayout();
  const { t } = useTranslation();
  // const currencySymbol = currency ? CURRENCY_SYMBOLS[currency] : '';

  const sortCategories = [
    { title: 'New', value: AllCampaignsSearchJobsOrderBy.CREATED_DATE },
    { title: 'Most Popular', value: AllCampaignsSearchJobsOrderBy.POPULAR },
    { title: 'High Reward', value: AllCampaignsSearchJobsOrderBy.HIGH_REWARD },
  ];

  useDeepCompareEffect(() => {
    setSearchInput(filter);
  }, [filter]);

  const onClickApply = () => {
    setFilter(searchInput);
    onCancel();
  };

  const onClickCancel = () => {
    onCancel();
    setSearchInput(filter);
  };

  const onClickClear = () => {
    setSearchInput({
      categoryIds: '',
      keyword: '',
      maxPrice: '',
      maxReward: '',
      minPrice: '',
      minReward: '',
      orderBy: AllCampaignsSearchJobsOrderBy.ORDER_NUMBER,
      promotionMethods: '',
    });
  };

  return (
    <div css={[styles.container, visible && styles.containerVisible]}>
      <div css={styles.searchFormContainer}>
        <TextFormV2
          css={styles.textFormSearch}
          placeholder={t('TextForm.Search')}
          value={searchInput.keyword}
          onChange={e => setSearchInput({ ...searchInput, keyword: e.target.value })}
        />
        <ThemeButton
          css={{ backgroundColor: '#fff !important', border: 'none', outline: 'none !important' }}
          text="Cancel"
          width="fit-content"
          onClick={onClickCancel}
        />
      </div>

      <div css={styles.filterContentContainer}>
        <div css={styles.sortCategoriesContainer}>
          {sortCategories.map(category => {
            const { title, value } = category;
            const isActive = searchInput.orderBy === value;

            return (
              <SortCategory
                isActive={isActive}
                key={value}
                onClick={() =>
                  setSearchInput({
                    ...searchInput,
                    orderBy: isActive ? AllCampaignsSearchJobsOrderBy.ORDER_NUMBER : value,
                  })
                }
              >
                {t(title)}
              </SortCategory>
            );
          })}
        </div>

        <MultiCheckBoxSelectContainer>
          <div>{t('Title.SNS / Promotion methods')}</div>
          <MultiCheckBoxSelect
            options={promotionMethods}
            values={searchInput.promotionMethods ? searchInput.promotionMethods.split(',').filter(Boolean) : []}
            onChange={val => setSearchInput({ ...searchInput, promotionMethods: val.toString() })}
          />
        </MultiCheckBoxSelectContainer>

        <MultiCheckBoxSelectContainer css={{ marginBottom: 0, ...(!isMobileView && { border: 'none' }) }}>
          <div>{t('BasicInfo.Categories')}</div>
          <MultiCheckBoxSelect
            checkedAllLabel="All Categories"
            options={campaignCategories.map(category => ({ label: category.label, value: Number(category.value) }))}
            values={
              searchInput.categoryIds
                ? searchInput.categoryIds
                    .split(',')
                    .map(i => Number(i))
                    .filter(Boolean)
                : []
            }
            onChange={val => setSearchInput({ ...searchInput, categoryIds: val.toString() })}
          />
        </MultiCheckBoxSelectContainer>

        {/* request to hide the area but API working with reward & price filter */}
        {/* <AmountInputContainer>
          <AmountInput
            placeholder={t('Placeholder.Min')}
            prependIcon={<AmountIcon>{currencySymbol}</AmountIcon>}
            title="Reward"
            value={searchInput.minReward ? formatNumberWithCommas(Number(searchInput.minReward), 0) : ''}
            onChange={e => setSearchInput({ ...searchInput, minReward: e.target.value.replace(/\D/g, '') })}
          />
          <div>~</div>
          <AmountInput
            placeholder={t('Placeholder.Max')}
            prependIcon={<AmountIcon>{currencySymbol}</AmountIcon>}
            value={searchInput.maxReward ? formatNumberWithCommas(Number(searchInput.maxReward), 0) : ''}
            onChange={e => setSearchInput({ ...searchInput, maxReward: e.target.value.replace(/\D/g, '') })}
          />
        </AmountInputContainer>

        <AmountInputContainer>
          <AmountInput
            placeholder={t('Placeholder.Min')}
            prependIcon={<AmountIcon>{currencySymbol}</AmountIcon>}
            title="Price"
            value={searchInput.minPrice ? formatNumberWithCommas(Number(searchInput.minPrice), 0) : ''}
            onChange={e => setSearchInput({ ...searchInput, minPrice: e.target.value.replace(/\D/g, '') })}
          />
          <div>~</div>
          <AmountInput
            placeholder={t('Placeholder.Max')}
            prependIcon={<AmountIcon>{currencySymbol}</AmountIcon>}
            value={searchInput.maxPrice ? formatNumberWithCommas(Number(searchInput.maxPrice), 0) : ''}
            onChange={e => setSearchInput({ ...searchInput, maxPrice: e.target.value.replace(/\D/g, '') })}
          />
        </AmountInputContainer> */}
      </div>

      <div css={styles.actionContainer}>
        <ThemeButton text="Clear" onClick={onClickClear} />
        <ThemeButton text="Apply" onClick={onClickApply} />
      </div>
    </div>
  );
};

// const AmountIcon = styled.div({
//   color: THEME.font.colors.gray.main,
//   fontSize: THEME.font.sizes.subHeading,
// });

// const AmountInput = styled(TextForm)({
//   [`@media (min-width: ${ViewportType.TABLET}px)`]: {
//     maxWidth: 158,
//   },

//   '& > div:last-child > input': {
//     textAlign: 'end',
//   },
// });

// const AmountInputContainer = styled.div({
//   alignItems: 'flex-end',
//   display: 'flex',
//   gap: THEME.box.gaps.s,
//   marginBottom: 24,
//   padding: '0 16px',

//   '& > div:nth-of-type(2)': {
//     alignSelf: 'center',
//     marginTop: 12,
//   },
// });

const MultiCheckBoxSelectContainer = styled.div({
  borderBottom: '1px solid #eef3f7',
  marginBottom: 24,
  padding: '0 16px 24px 16px',

  '& > div:nth-of-type(1)': {
    color: THEME.font.colors.gray.main,
    fontSize: THEME.font.sizes.normal,
    marginBottom: 12,
  },
});

const SortCategory = styled.div<{ isActive: boolean }>(({ isActive }) => ({
  alignItems: 'center',
  border: `1px solid ${isActive ? '#27313b' : '#c5d0da'}`,
  backgroundColor: isActive ? '#27313b' : '#fff',
  borderRadius: 32,
  color: isActive ? '#fff' : '#6E7C89',
  cursor: 'pointer',
  display: 'flex',
  fontSize: THEME.font.sizes.normal,
  fontWeight: 600,
  height: 32,
  padding: '0 16px',
}));

const styles = {
  actionContainer: css({
    backgroundColor: THEME.colors.white,
    borderRadius: '0 0 23px 23px',
    borderTop: '1px solid #dee5ec',
    display: 'flex',
    gap: THEME.box.gaps.s,
    padding: 16,
    width: 'fill-available',
    zIndex: 1,

    '& > button': {
      borderRadius: 9,
      height: 48,
    },

    '& > button:nth-of-type(2)': {
      backgroundColor: THEME.colors.black.main,
      color: THEME.font.colors.white,
    },

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      bottom: 0,
      position: 'fixed',
    },
  }),
  container: css({
    backgroundColor: THEME.colors.white,
    borderRadius: 23,
    display: 'none',
    width: '100%',

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      borderRadius: 0,
      display: 'unset',
      height: '100%',
      overflow: 'hidden',
      position: 'fixed',
      top: 0,
      transform: 'translateX(100%)',
      transition: 'transform 0.5s',
      visibility: 'hidden',
      zIndex: 6,

      '[dir="rtl"] &': {
        transform: 'translateX(-100%)',
      },
    },
  }),
  containerVisible: css({
    display: 'block',

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      display: 'unset',
      transform: 'translateX(0) !important',
      visibility: 'visible',
    },
  }),
  filterContentContainer: css({
    paddingTop: 10,

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      height: 'calc(100% - 153px)',
      margin: '80px 0 81px',
      overflow: 'auto',
      padding: '0 0 24px',
    },
  }),
  searchFormContainer: css({
    alignItems: 'center',
    backgroundColor: THEME.colors.white,
    borderRadius: '23px 23px 0 0',
    boxShadow: THEME.box.shadows.outer,
    display: 'flex',
    padding: 16,
    width: 'fill-available',

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      borderRadius: 0,
      padding: '16px 0 16px 16px',
      position: 'fixed',
      top: 0,

      '[dir="rtl"] &': {
        padding: '16px 16px 16px',
      },
    },
  }),
  sortCategoriesContainer: css({
    display: 'flex',
    gap: THEME.box.gaps.s,
    marginBottom: 24,
    padding: '8px 16px',
  }),
  textFormSearch: css({
    '& > div': {
      height: 38,
    },
  }),
};

export default SearchFilter;
