import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import ReactSlick, { Settings } from 'react-slick';
import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import analyticsBanner from '@src/assets/img/findJobs/analyticsBanner.png';
import helpGuide from '@src/assets/img/findJobs/helpGuide.png';
import linkInBioBanner from '@src/assets/img/findJobs/linkInBioBanner.png';
import settingsBanner from '@src/assets/img/findJobs/settingsBanner.png';
import telescopeSearch from '@src/assets/img/findJobs/telescopeSearch.png';
import walletWithCoins from '@src/assets/img/findJobs/walletWithCoins.png';
import { Icomoon } from '@src/components/atoms';
import { ListLoading, NotificationBadge, Revenue, ThemeButton, YoutubeCmsWidget } from '@src/components/molecules';
import { Card, DialogFBReconnect, SearchFormV2 } from '@src/components/shared';
import { THEME } from '@src/libs/theme';
import {
  useGetAllCampaignsForSearchJobQuery,
  useInfluencerProfileV2Query,
  useNeedReconnectForFacebookQuery,
  usePaymentRequestQuery,
} from '@src/graphql/hooks';
import { LIMIT } from '@src/libs/constant';
import { useHelpCenterUrl } from '@src/libs/help';
import {
  useAuthData,
  useBottomScrollListener,
  useDeepCompareEffect,
  useDirLayout,
  useFilter,
  useGlobalLayout,
  useInfluencerCategories,
  usePageLayout,
  useQueryHelper,
  useScrollToPostion,
} from '@src/libs/hooks';
import { ViewportType } from '@src/libs/types';
import useInfluencerProfile from '@src/pages/Analytics/useInfluencerProfile';
import { facebookAuthState, useRecoil } from '@src/recoilAtoms';
import { generatePath, ROUTES } from '@src/shared/routes';
import {
  AllCampaignsSearchJobsOrderBy,
  CampaignSocialMediaType,
  GetAllCampaignsForSearchJobQuery,
  SocialAccountType,
} from '@src/__generated__/globalTypes';
import SearchFilter, { FilterItems } from './SearchFilter';

declare const IS_DISABLED_ON_PROD: boolean;

type GetAllCampaignsForSearchJob = NonNullable<GetAllCampaignsForSearchJobQuery['getAllCampaignsForSearchJob']>;

const Listings = () => {
  const [campaigns, setCampaigns] = useState<GetAllCampaignsForSearchJob['campaigns']>([]);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isFilter, setIsFilter] = useState<boolean>(false);
  const [limit, setLimit] = useState<number>(LIMIT);
  const [searchParams] = useSearchParams();
  const { filter, setFilter } = useFilter({
    categoryIds: searchParams.get('categoryIds') || '',
    keyword: searchParams.get('keyword') || '',
    maxPrice: searchParams.get('maxPrice') || '',
    maxReward: searchParams.get('maxReward') || '',
    minPrice: searchParams.get('minPrice') || '',
    minReward: searchParams.get('minReward') || '',
    orderBy:
      (searchParams.get('orderBy') as AllCampaignsSearchJobsOrderBy) || AllCampaignsSearchJobsOrderBy.ORDER_NUMBER,
    promotionMethods: searchParams.get('promotionMethods') || '',
  });
  const [keyword, setKeyword] = useState<string>(filter?.keyword || '');
  const { recoilState, setRecoilState } = useRecoil(facebookAuthState);
  const { countryId, isAvailableCountry, isYoutubeCmsRevenue, userId } = useAuthData();
  const { hasIntercomWidget, hasLineWidget, isUuum } = useGlobalLayout();
  const { joinCampaignHelp } = useHelpCenterUrl();
  const { navigate, search, t } = useQueryHelper();
  const { isMobileView } = usePageLayout();
  const { isRtl } = useDirLayout();
  const { influencerCategories } = useInfluencerCategories();

  const { data: dataInfluencerProfile } = useInfluencerProfileV2Query({
    variables: {
      pk: Number(userId),
    },
  });
  const isJPInfluencer = dataInfluencerProfile?.influencerProfileV2?.country.id === 'JP';
  const { data: dataNeedReconnectFacebook } = useNeedReconnectForFacebookQuery({
    // pending user_post permission from FB
    skip: IS_DISABLED_ON_PROD,
  });
  const needReconnectFacebook = !!dataNeedReconnectFacebook?.needReconnectForFacebook?.need;
  const { data: paymentData } = usePaymentRequestQuery({
    skip: countryId === 'US' || !isAvailableCountry,
  });

  const { scrollOnTop, scrollToTop } = useScrollToPostion();
  const currency = paymentData?.paymentRequest?.currency || '';
  const { hasFBAccount, hasIGAccount, hasTWAccount, hasYTAccount } = useInfluencerProfile();
  const analyticsRedirectType = hasIGAccount
    ? SocialAccountType.INSTAGRAM
    : hasFBAccount
    ? SocialAccountType.FACEBOOK
    : hasTWAccount
    ? SocialAccountType.TWITTER
    : hasYTAccount
    ? SocialAccountType.YOUTUBE
    : SocialAccountType.TIKTOK;

  const settings: Settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
  };
  const banners = [
    {
      background: linkInBioBanner,
      backgroundLinear: 'linear-gradient(98.2deg, #C7DDFF 10.6%, #C5A2FF 41.26%, #FDC879 72.34%, #EDD57E 90.15%)',
      buttonTitle: 'Start Now',
      contentCss: { width: '35%' },
      title: 'Create your own Link in Bio',
      to: ROUTES.LINK_IN_BIO,
    },
    {
      background: analyticsBanner,
      backgroundLinear: 'linear-gradient(98.2deg, #FFEACB 10.6%, #FFE6A6 41.26%, #FFB68D 67.78%, #FFB9D2 90.15%)',
      buttonTitle: 'Check',
      contentCss: { width: '42%' },
      title: 'Analyze and increase your fan base',
      to: generatePath(ROUTES.ANALYTICS, {}, { sm: analyticsRedirectType }),
    },
    {
      background: settingsBanner,
      backgroundLinear: 'linear-gradient(98.2deg, #FFCEE0 10.6%, #EEBDFF 33.81%, #CEAFFF 57.42%, #C6DDFF 90.15%)',
      buttonTitle: 'Settings',
      contentCss: { width: '47%' },
      title: `Let's tie up accounts and do all kinds of work`,
      to: ROUTES.MY_PAGE,
    },
    {
      background: helpGuide,
      backgroundLinear: '#9adaec',
      buttonTitle: 'Check',
      contentCss: { width: '50%' },
      subTitle: 'On joining campaigns and earning rewards',
      target: '_blank',
      title: 'Help Guide',
      to: joinCampaignHelp,
    },
  ];
  const promotionMethods = [
    { label: 'Facebook', value: CampaignSocialMediaType.FACEBOOK },
    { label: 'Instagram', value: CampaignSocialMediaType.INSTAGRAM },
    { label: 'TikTok', value: CampaignSocialMediaType.TIKTOK },
    { label: 'X(Twitter)', value: CampaignSocialMediaType.TWITTER },
    { label: 'YouTube', value: CampaignSocialMediaType.YOUTUBE },
    { label: 'Others (Email/Offline/Website)', value: CampaignSocialMediaType.OTHER },
  ];

  useDeepCompareEffect(() => {
    setLimit(LIMIT);
  }, [filter]);

  useEffect(() => {
    const today = format(new Date(), 'yyyyMMdd');
    // to show the reconnect dialog once a day until user relogin
    if (needReconnectFacebook && recoilState.reconnectDialogShownDate !== today) {
      setIsDialogOpen(needReconnectFacebook);
      setRecoilState({ reconnectDialogShownDate: today });
    }
  }, [needReconnectFacebook, recoilState.reconnectDialogShownDate]);

  useEffect(() => {
    if (!search && !!influencerCategories.length) {
      setFilter({
        ...filter,
        categoryIds: influencerCategories.map(category => Number(category.value)).toString(),
        promotionMethods: promotionMethods.map(method => method.value).toString(),
      });
    }
  }, [search, influencerCategories.length]);

  const { loading } = useGetAllCampaignsForSearchJobQuery({
    fetchPolicy: 'no-cache',
    skip: !search || !isAvailableCountry,
    variables: {
      input: {
        categoryIds:
          filter.categoryIds
            ?.split(',')
            .filter(Boolean)
            .map(i => Number(i)) || [],
        limit,
        offset: 0,
        orderBy: filter.orderBy || AllCampaignsSearchJobsOrderBy.ORDER_NUMBER,
        promotionMethods: filter.promotionMethods?.split(',').filter(Boolean) as CampaignSocialMediaType[],
        ...(filter.keyword && { keyword: filter.keyword }),
        ...(filter.maxPrice && { maxPrice: Number(filter.maxPrice) }),
        ...(filter.maxReward && { maxReward: Number(filter.maxReward) }),
        ...(filter.minPrice && { minPrice: Number(filter.minPrice) }),
        ...(filter.minReward && { minReward: Number(filter.minReward) }),
      },
    },
    onCompleted: data => {
      setCampaigns(data.getAllCampaignsForSearchJob?.campaigns || []);
    },
  });

  const onClickReconnectFacebook = () => {
    // to remove dialog lock scroll
    setIsDialogOpen(false);
    // redirect after dialog closed 0.1s
    setTimeout(() => {
      navigate(ROUTES.MY_PAGE_SOCIAL_CONNECT_FACEBOOK_GRANT_PERMISSION);
    }, 100);
  };

  const onScroll = () => {
    if (campaigns.length === limit) {
      setLimit(max => max + LIMIT);
    }
  };

  useBottomScrollListener(onScroll, [campaigns.length, limit]);

  return (
    <Container removePadding={isMobileView && isFilter}>
      <DialogFBReconnect
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onClickReconnect={onClickReconnectFacebook}
      />

      <div css={{ width: '100%', ...(!isMobileView && { maxWidth: 648 }) }}>
        <SearchFilter
          campaignCategories={influencerCategories}
          currency={currency}
          filter={filter as FilterItems}
          promotionMethods={promotionMethods}
          visible={isFilter}
          onCancel={() => setIsFilter(false)}
          setFilter={setFilter}
        />

        {!isFilter && (
          <>
            <div css={styles.searchFormContainer}>
              <SearchFormV2
                appendIcon={
                  <Icomoon
                    css={{
                      borderLeft: '1px solid #eef3f7',
                      cursor: 'pointer',
                      ...(isRtl ? { paddingRight: 8 } : { paddingLeft: 8 }),
                    }}
                    icon="air-filter"
                    size={22}
                    onClick={() => setIsFilter(true)}
                  />
                }
                placeholder={t<string>('SearchForm.Search by Keywords')}
                value={keyword}
                onChange={e => setKeyword(e.target.value)}
                onEnterKeyPress={() => setFilter({ ...filter, keyword })}
              />
              {!isMobileView && (
                <div>
                  <NotificationBadge />
                </div>
              )}
            </div>

            <div css={{ ...(isMobileView && { marginTop: 64 }) }}>
              {isYoutubeCmsRevenue ? (
                <Link css={styles.youtubeCmsBanner} to={ROUTES.YOUTUBE_CMS_REVENUE}>
                  <div>
                    <div>{t('Check YouTube short revenue')}</div>
                    <div>
                      {isRtl ? <div>&#8594;</div> : <div>&#8592;</div>}
                      <ThemeButton text="Go to report" />
                    </div>
                  </div>
                  <div>
                    <img src={walletWithCoins} />
                  </div>
                </Link>
              ) : (
                <ReactSlick css={styles.reactSlick} {...settings}>
                  {banners.map((banner, index) => {
                    const { background, backgroundLinear, buttonTitle, contentCss, subTitle, target, title, to } =
                      banner;

                    return (
                      <Banner
                        background={background}
                        backgroundLinear={backgroundLinear}
                        key={index}
                        target={target}
                        to={to}
                      >
                        <div css={[styles.banner, contentCss]}>
                          <div css={{ display: 'grid', gap: THEME.box.gaps.xs }}>
                            <div css={styles.bannerTitle}>{t(title)}</div>
                            {subTitle && <div css={styles.bannerSubTitle}>{t(`Annotation.${subTitle}`)}</div>}
                          </div>
                          <div css={styles.bannerButton}>{t(`Button.${buttonTitle}`)}</div>
                        </div>
                      </Banner>
                    );
                  })}
                </ReactSlick>
              )}

              {!isUuum && <Revenue />}

              {!!campaigns.length ? (
                <div css={{ display: 'grid', gap: THEME.box.gaps.l }}>
                  {campaigns.map(campaign => {
                    const { id } = campaign;

                    return <Card campaign={campaign} isFindJobs key={id} />;
                  })}
                </div>
              ) : (
                <div css={styles.noCampaignContainer}>
                  <div>
                    <img alt="telescopeSearch" height="72" src={telescopeSearch} width="144" />
                    <div>{t('Annotation.No Campaigns found')}</div>
                    <div>{t('Annotation.Sorry, we currently do not have campaigns in your location')}</div>
                  </div>
                </div>
              )}

              {loading && (
                <ListLoading
                  css={{ marginTop: 8 }}
                  height="unset"
                  isTransparentBg
                  spinnerProps={{
                    color: THEME.colors.disabled,
                    size: '32px',
                    thickness: '4px',
                  }}
                />
              )}
            </div>
          </>
        )}
      </div>

      {!isJPInfluencer && !scrollOnTop && (
        <div
          css={[
            styles.buttonScrollToTop,
            ...(isRtl ? [{ left: 24 }] : [{ right: hasIntercomWidget ? 80 : hasLineWidget ? 88 : 24 }]),
          ]}
          onClick={scrollToTop}
        >
          <Icomoon css={{ transform: 'rotate(180deg)' }} icon="arrow-down" size={10} />
        </div>
      )}

      <YoutubeCmsWidget />
    </Container>
  );
};

const Banner = styled(Link, { shouldForwardProp: prop => isPropValid(prop) })<{
  background: string;
  backgroundLinear: string;
}>(({ background, backgroundLinear }) => ({
  alignItems: 'center',
  background: `url(${background}), ${backgroundLinear}`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right',
  backgroundSize: 'auto 121px',
  borderRadius: 15,
  display: 'flex !important',
  height: 120,
  width: 'fill-available',

  [`@media (min-width: ${ViewportType.TABLET}px)`]: {
    backgroundImage: `url(${background}), ${backgroundLinear}`,
    backgroundSize: 'auto 200px',
    height: 200,
  },

  '[dir="rtl"] &': {
    backgroundPosition: 'left',
    justifyContent: 'flex-end',
  },
}));

const Container = styled.div<{ removePadding: boolean }>(({ removePadding }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: removePadding ? '0' : '16px 16px',
}));

const styles = {
  banner: css({
    display: 'grid',
    gap: THEME.box.gaps.s,
    padding: '0 16px',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      padding: '0 32px',
    },
  }),
  bannerButton: css({
    alignItems: 'center',
    background: THEME.colors.black.main,
    borderRadius: 32,
    color: THEME.font.colors.white,
    display: 'flex',
    fontSize: THEME.font.sizes.subordinate,
    fontWeight: 600,
    height: 32,
    padding: '0 16px',
    width: 'fit-content',

    '[dir="rtl"] &': {
      justifySelf: 'flex-end',
    },
  }),
  bannerSubTitle: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.subordinate,

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      fontSize: THEME.font.sizes.subHeading,
    },
  }),
  bannerTitle: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.normal,
    fontWeight: 600,

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      fontSize: THEME.font.sizes.title,
    },
  }),
  buttonScrollToTop: css({
    alignItems: 'center',
    background: THEME.colors.white,
    borderRadius: '50%',
    bottom: 24,
    boxShadow: THEME.box.shadows.outer,
    cursor: 'pointer',
    display: 'flex',
    height: 32,
    justifyContent: 'center',
    position: 'fixed',
    width: 32,
  }),
  noCampaignContainer: css({
    '--availableSpace': '32px',
    '--banner': 'calc(200px + 16px)',
    '--revenue': 'calc(48px + 16px)',
    '--searchBar': 'calc(72px + 24px)',

    alignItems: 'center',
    display: 'grid',
    height: 300,
    justifyContent: 'center',
    width: 'fill-available',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      height: 'calc(100vh - var(--availableSpace) - var(--searchBar) - var(--banner) - var(--revenue))',
    },

    '& > div': {
      color: THEME.colors.gray.main,
      display: 'grid',
      fontSize: THEME.font.sizes.subordinate,
      justifyItems: 'center',
      textAlign: 'center',
      width: 214,

      '& > img': {
        marginBottom: 24,
      },

      '& > div:nth-of-type(1)': {
        color: THEME.colors.black.main,
        fontSize: THEME.font.sizes.subHeading,
        fontWeight: 600,
        marginBottom: 8,
      },
    },
  }),
  reactSlick: css({
    marginBottom: 16,

    '& .slick-arrow': {
      display: 'none !important',
    },

    '& .slick-dots': {
      bottom: 8,
      position: 'absolute',

      '& > li': {
        margin: 0,
        width: 14,

        '& > button::before': {
          color: THEME.font.colors.white,
          fontSize: 6,
        },
      },
    },
  }),
  searchFormContainer: css({
    alignItems: 'center',
    background: THEME.colors.white,
    borderRadius: 23,
    display: 'flex',
    gap: THEME.box.gaps.s,
    marginBottom: 24,
    padding: 16,

    '& > div:nth-of-type(1)': {
      width: '70%',

      [`@media (max-width: ${ViewportType.TABLET}px)`]: {
        width: '100%',
      },
    },

    '& > div:nth-of-type(2)': {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '30%',
    },

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      borderRadius: 0,
      left: 0,
      padding: '8px 16px 16px 16px',
      position: 'fixed',
      top: 40,
      width: 'fill-available',
      zIndex: 1,
    },
  }),
  youtubeCmsBanner: css({
    alignItems: 'center',
    borderRadius: 15,
    background: 'linear-gradient(to right, #50bffb, #5db7f2, #63c1e8)',
    cursor: 'pointer',
    display: 'flex',
    gap: THEME.box.gaps.s,
    marginBottom: 16,
    padding: '12px 16px',
    overflow: 'hidden',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      marginBottom: 24,
      padding: '40px 32px',
    },

    '& > div:nth-of-type(1)': {
      display: 'grid',
      flexBasis: '50%',
      gap: THEME.box.gaps.l,

      '& > div:nth-of-type(1)': {
        color: THEME.font.colors.white,
        fontSize: THEME.font.sizes.normal,
        fontWeight: 600,

        [`@media (min-width: ${ViewportType.TABLET}px)`]: {
          fontSize: 26.4,
        },
      },

      '& > div:nth-of-type(2)': {
        alignItems: 'center',
        color: THEME.font.colors.white,
        display: 'flex',
        fontSize: THEME.font.sizes.heading,
        fontWeight: 600,
        gap: 8,

        [`@media (min-width: ${ViewportType.TABLET}px)`]: {
          fontSize: THEME.font.sizes.title,
        },

        '& > a, button': {
          borderRadius: 32,
          padding: '8px 16px',
          width: 'unset',

          [`@media (min-width: ${ViewportType.TABLET}px)`]: {
            padding: '8px 24px',
          },

          '& span': {
            color: THEME.font.colors.black.main,
            fontSize: THEME.font.sizes.subordinate,
            fontWeight: 600,
            padding: 'unset',

            [`@media (min-width: ${ViewportType.TABLET}px)`]: {
              fontSize: THEME.font.sizes.normal,
            },
          },
        },
      },
    },

    '& > div:nth-of-type(2)': {
      display: 'flex',
      flexBasis: '50%',
      justifyContent: 'flex-end',

      '& > img': {
        height: 92,
        width: 95,

        [`@media (min-width: ${ViewportType.TABLET}px)`]: {
          height: 135,
          width: 140,
        },
      },
    },
  }),
};

export default Listings;
